$(document).ready(function () {
    menuClick();
    fixHeader();
    qaCollapse();
})

function menuClick() {
    var $menuBtn = $('#aside-menu');
    var $menuList = $('#aside-list');
    var $menuLinkList = $('#aside-list a')
    var header = $("#header");
    var headerHeight = header[0].offsetHeight;
    if ($menuBtn.length == 0 || $menuLinkList.length == 0) {
        return;
    }
    $menuBtn.click(function () {
        $menuBtn.toggleClass('active');
        $menuList.toggleClass('active');
    });
    $menuLinkList.click(function () {
        $menuBtn.toggleClass('active');
        $menuList.toggleClass('active');
        header.data('freeze', true);
        header.attr('style', 'top: -' + headerHeight + 'px');
        // setTimeout(() => {
        //     header.data('freeze', false);
        // }, 2000);
        setTimeout(function(){
            header.data('freeze', false);
        },2000);
    })
}

function fixHeader() {
    // 上一次的Y(判斷往上或往下);
    var lastScrollTop = 0;
    $(window).scroll(function () {
        var st = $(this).scrollTop();
        var header = $("#header");
        var headerHeight = header[0].offsetHeight;
        var topPos = +header.css('top').replace('px', '');
        var marginalVal = lastScrollTop - st;
        if (header.data('freeze') == true) {
            return;
        }
        if (lastScrollTop > st) {
            //往上
            marginalVal = (topPos > 0) ? 0 : lastScrollTop - st;
        } else {
            if ($("#aside-menu").hasClass('active')) {
                return;
            }
            //往下
            marginalVal = (headerHeight + topPos < 0) ? 0 : lastScrollTop - st;
        }
        topPos = (topPos + marginalVal > 0 || st <= 0) ? 0 : topPos + (marginalVal * 0.5);
        header.attr('style', 'top:' + topPos + 'px');
        lastScrollTop = st;
    });
}

function qaCollapse() {
    var $qaItems = $('.qa-item');
    var $qaContent = $('.qa-item > .qa-ques-content')
    if ($qaItems.length == 0) {
        return;
    }
    $qaContent.hide();
    $qaItems.click(function () {
        var $content = $(this).children('.qa-ques-content');
        var isActive = $content.attr('style').includes('display: block');
        $qaContent.slideUp();
        if (isActive) {
            return;
        }
        $content.slideDown();
    });
}