$(document).ready(function () {
    common();
    goToTop();
    blogSharing();
    jumpMenu();
    loadGoogleFont();
    new WOW().init();
});


function jumpMenu() {

    //選單跳段落
    $('.nav-list li.nav-item a,.aside-list li.aside-item a').click(function () {

        console.log('click menu link');

        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {
            jumpSection(this.hash,0,500);
            // return false;
        }
    });

    //處理編輯器要跳段落
    $('.editor a').click(function () {
        var $anchor = $(this);
        console.log('click editor internal link');
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') || location.hostname == this.hostname) {

            if ($anchor.attr('href').indexOf('http') < 0) {
                //不是完整連結才要跳
                jumpSection(this.hash,0,500);
                // return false;
            }

        }
    });


    /*才能避免slider產生後的高度讓跳cut不正確*/
    var jumpHash = location.hash;
    //超連結跳段落
    if (jumpHash !== '') {
        var newjumpHash = jumpHash.replace(/\//, '');
        console.log('detect jump from link url:' + newjumpHash);
        Pace.on('done', function () {
            jumpSection(newjumpHash,0,500);
            // return false;
        });
    }

    $('.yellow-waxing-btn').click(function(){
        jumpSection("#store",0,500);
    });

}

function blogSharing() {
    initJsSocial($('#blogSharing'));
}

// function productSharing() {
//     initJsSocial($('#productSharing'));
// }

function initJsSocial($targetElem) {
    Pace.on('done', function () {
        if ($targetElem.length > 0) {
            $targetElem.jsSocials({
                shares: ["twitter", "facebook"]
            });
        }
    });

}

function goToTop() {
    var $body =  $('html, body');
    var $scrollToTop = $('.scrollToTop');
    $(window).scroll(function () {
        if ($(this).scrollTop() > 300) {
            $scrollToTop.fadeIn();
        } else {
            $scrollToTop.fadeOut();
        }
    });

    //Click event to scroll to top
    $scrollToTop.click(function () {
        $body.animate({
            scrollTop: 0
        }, 800);
        return false;
    });
}

function loadGoogleFont() {
    WebFont.load({
        timeout: 2000,
        google: {
            
            families: [
                // 'Roboto&display=swap'
                'Noto Sans TC&display=swap']
        }
    });
}